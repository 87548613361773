
import {defineComponent} from 'vue';
import {userInfoStore} from "@/user/model/currentUserInfo";
import CustomButton from "@/common/button/CustomButton.vue";

export default defineComponent({
  components: {CustomButton},
  data() {
    return {
      role: 'propertyOwner',
      isButtonActive: true
    }
  },
  setup() {
    const currentUserInfoStore = userInfoStore()
    return {currentUserInfoStore}
  },
  methods: {
    async submitRoleSelection() {
      if (this.role !== '') {
        const user = await this.$ports.user.getAuthenticatedUser();
        const userInfo = await this.$ports.user.getUserInfo();
        if (user != null && userInfo != null) {
          await this.$ports.user.updateUserInfo({
            userKey: user.userKey,
            role: this.role,
            companyName: userInfo.companyName,
          });
          this.currentUserInfoStore.updateUserRole(this.role);
          if (this.role === 'propertyOwner') {
            if (userInfo.role === null) {
              await this.$router.push({
                name: 'properties',
              });
            } else {
              await this.$router.push({
                name: 'profile'
              });
            }
          } else {
            await this.$router.push({
              name: 'service-provider-create'
            });
          }
        }
      }
    },
    setUserRole(userRole: string) {
      this.role = userRole;
    },
    goBack() {
      this.$router.back();
    },
  },
  created() {
    if (window.history.state.back !== '/profile') {
      this.isButtonActive = false;
    }
  },
})


